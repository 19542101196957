import App from 'next/app';
import Router from 'next/router';
import getConfig from 'next/config';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';
import Script from 'next/script';
import { getOptimizelyData, getHostname } from 'lib/dataFetchers';
import { parseInitialProps } from 'Utils';
import { validateUser, saveUser } from 'User/Actions';
import { v4 as uuidv4 } from 'uuid';
import { isomorphicSetCookie } from 'IsomorphicUtils';
import isServerSide from 'Utils/isServerSide';
import { setCookieClientSide } from 'lib/cookies';
import {
  saveUserUtmTracking,
  saveUtmParamsToCookie,
  trackGAPageView,
} from 'lib/tracking';
import {
  disableFullStory,
  privacyConsentFullStoryInit,
} from 'lib/fullStory/fullStory';
import { CountryDataProvider } from 'lib/useCountryData';
import { expireOptOutCookies } from 'lib/cookies/expireOptOutCookies';
import i18n from 'i18n/i18n';
import getOptimizelyClient from 'lib/optimizely/getOptimizelyClient';
import reduxWrapper from '../store';

import 'react-phone-number-input/style.css';
import '@uppy/core/dist/style.css';
// TODO: Consider fixing linting error when editing file next
// eslint-disable-next-line import/no-extraneous-dependencies
import '@uppy/drag-drop/dist/style.css';
import 'pdfjs-dist/web/pdf_viewer.css';
import 'styles/bootstrap.css';
import 'styles/global.scss';

const { publicRuntimeConfig } = getConfig();

const dropboxPrivacyConsentModuleConfig = {
  consentChangedCallback: (categories) => {
    window.uetq = window.uetq || [];
    const generalMarketingAndAdvertising =
      categories['general marketing and advertising'];
    const { analytics } = categories;
    window.uetq.push('consent', 'update', {
      ad_storage:
        generalMarketingAndAdvertising ||
        generalMarketingAndAdvertising === undefined
          ? 'granted'
          : 'denied',
    });
    window.gtag('consent', 'update', {
      ad_storage:
        generalMarketingAndAdvertising ||
        generalMarketingAndAdvertising === undefined
          ? 'granted'
          : 'denied',
      analytics_storage:
        analytics || analytics === undefined ? 'granted' : 'denied',
      personalization_storage: 'denied',
    });
    // https://help.ads.microsoft.com/#apex/ads/en/56955/1/#exp7345
    if (Cookies.get('__Secure-dbx_do_not_sell')) {
      setCookieClientSide('_uetmsdns', '1');
    }
    if (!Cookies.get('__Secure-dbx_do_not_sell') && Cookies.get('_uetmsdns')) {
      Cookies.remove('_uetmsdns');
    }
    if (!analytics && !generalMarketingAndAdvertising) {
      disableFullStory();
    }
    const dbxCookie = Cookies.get('__Secure-dbx_consent');
    const userInteracted = dbxCookie
      ? JSON.parse(dbxCookie).userInteracted
      : false;
    if (analytics && generalMarketingAndAdvertising && userInteracted) {
      privacyConsentFullStoryInit();
    }

    expireOptOutCookies(categories);
  },
  disableBanner: true,
  injectFloatingButton: false,
  injectToFooter: false,
  priorConsentCallback: (categories) => {
    const countryIsoAlpha2 = localStorage.getItem('countryIsoAlpha2');
    // since we are hiding the banner for US market
    // default categories comes back false for everything except strictly necessary
    // this causes us to update the consent to denied for ad_storage and analytics_storage
    // as well as expire all non-essential cookies on page load
    if (countryIsoAlpha2 !== 'US') {
      window.uetq = window.uetq || [];
      const generalMarketingAndAdvertising =
        categories['general marketing and advertising'];
      const { analytics } = categories;
      window.uetq.push('consent', 'update', {
        ad_storage:
          generalMarketingAndAdvertising ||
          generalMarketingAndAdvertising === undefined
            ? 'granted'
            : 'denied',
      });
      window.gtag('consent', 'update', {
        ad_storage:
          generalMarketingAndAdvertising ||
          generalMarketingAndAdvertising === undefined
            ? 'granted'
            : 'denied',
        analytics_storage:
          analytics || analytics === undefined ? 'granted' : 'denied',
        personalization_storage: 'denied',
      });
      expireOptOutCookies(categories);
    }
    // https://help.ads.microsoft.com/#apex/ads/en/56955/1/#exp7345
    if (Cookies.get('__Secure-dbx_do_not_sell')) {
      setCookieClientSide('_uetmsdns', '1');
    }
    if (!Cookies.get('__Secure-dbx_do_not_sell') && Cookies.get('_uetmsdns')) {
      Cookies.remove('_uetmsdns');
    }
  },
  thirdPartyConfig: {
    cookieDomain: 'formswift.com',
  },
};

class FormSwift extends App {
  static async getInitialProps({ Component, ctx }) {
    const { pathname, query, req, store } = ctx;
    const { documentType: documentTypeFromUrlQuery } = query;
    const { dispatch } = store;
    const { cookies } = parseInitialProps(req);

    let { optimizelyUserId } = cookies;
    // Get the optimizelyUserId associated with the user.
    // We store a first-party cookie `optimizelyUserId` to manage variations
    // for both logged-out and logged-in users.
    if (!optimizelyUserId) {
      optimizelyUserId = uuidv4();
      isomorphicSetCookie('optimizelyUserId', optimizelyUserId, ctx);
    }

    // Required to pre-load the datafile and client for Optimizely
    // needed for some pages that use Optimizely in getInitialProps
    // This is stateful and should not be removed
    // TODO: refactor to hoist optimizely provider to app
    const { datafile } = await getOptimizelyData(ctx, cookies);
    getOptimizelyClient(datafile);

    const hostname = getHostname(ctx);
    const {
      accountemail,
      defaultDocumentType: defaultDocumentTypeFromCookie,
      userToken,
      userid,
    } = cookies;
    const dbxConsentCookie = cookies['__Secure-dbx_consent'];
    const user = await dispatch(validateUser(userid, userToken));
    const { loggedIn } = user;

    if (loggedIn) {
      dispatch(saveUser(userid, userToken, accountemail));
      saveUserUtmTracking(hostname, pathname, query, userid, userToken);
    } else {
      saveUtmParamsToCookie(query, ctx);
    }
    const countryIsoAlpha2 =
      ctx.req?.headers['cf-ipcountry'] ||
      (!isServerSide() ? localStorage.getItem('countryIsoAlpha2') : undefined);

    const initialProps = {
      pageProps: {
        ...(Component.getInitialProps
          ? await Component.getInitialProps({
              ...ctx,
              loggedIn,
              optimizelyUserId,
            })
          : {}),
        countryIsoAlpha2,
        dbxConsentCookie,
        defaultDocumentTypeFromCookie,
        documentTypeFromUrlQuery,
        loggedIn,
        pathname,
      },
    };

    return initialProps;
  }

  componentDidMount() {
    if (
      publicRuntimeConfig.APP_ENV === 'local' ||
      publicRuntimeConfig.APP_ENV === 'staging'
    ) {
      window.trackToConsole = true;
    }
    if (this.props.pageProps.countryIsoAlpha2) {
      localStorage.setItem(
        'countryIsoAlpha2',
        this.props.pageProps.countryIsoAlpha2
      );
    }
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', trackGAPageView);
    disableFullStory();
  }

  render() {
    const { Component, pageProps } = this.props;
    const { userId } = pageProps;

    Sentry.configureScope((scope) => {
      scope.setUser({ id: userId });
    });

    // Show the banner if the user is not logged in and the country is not US
    const showBanner = !userId && pageProps.countryIsoAlpha2 !== 'US';
    dropboxPrivacyConsentModuleConfig.disableBanner = !showBanner;
    return (
      <>
        {/*
        Chargify.js is loaded globally to handle some bugs w/ SPA
        transitions in Next.js
        https://github.com/vercel/next.js/discussions/17919
        */}
        <Script
          async
          data-cfasync="false"
          id="chargify-js"
          src="https://js.chargify.com/latest/chargify.js"
        />
        <Script
          async
          data-cfasync="false"
          id="stripe-js"
          src="https://js.stripe.com/v3/"
        />
        {/*
          Script tags must go in App for Next.js < 12.1.6
          https://nextjs.org/docs/messages/no-script-in-document
          https://nextjs.org/docs/pages/api-reference/components/script#beforeinteractive
        */}
        <Script
          async
          id="google-tag-manager"
          src="https://www.googletagmanager.com/gtag/js?id=AW-849235305"
        />
        <Script async src="https://www.dropbox.com/pithos/ux_analytics" />
        <Script
          async
          defer
          id="privacy-consent"
          onLoad={() => {
            // Initialization has to be done asynchronously
            // https://www.dropbox.com/scl/fi/9y8b9n364wfhwoba16rpy/Privacy-Consent-Embeddable.paper?dl=0&rlkey=27v05bk1ktpvhjzkfqpq81u0k#:uid=629105883344915289264721&h2=window.dropbox.privacyConsent.
            const timeout = setTimeout(
              function waitForPrivacyConsentInitialization() {
                if (
                  typeof window.dropbox !== 'undefined' &&
                  typeof window.dropbox.privacyConsent.init !== 'undefined'
                ) {
                  window.dropbox.privacyConsent.init(
                    dropboxPrivacyConsentModuleConfig
                  );
                } else {
                  setTimeout(waitForPrivacyConsentInitialization, 10);
                }
              }
            );
            // Set a maximum amount of time to wait for the privacy
            // consent module to load
            setTimeout(() => {
              clearTimeout(timeout);
            }, 10000);
          }}
          src="https://www.dropbox.com/pithos/privacy_consent"
        />
        <I18nextProvider i18n={i18n}>
          <CountryDataProvider
            value={{ isoAlpha2: pageProps.countryIsoAlpha2 }}
          >
            <Component {...pageProps} />
          </CountryDataProvider>
        </I18nextProvider>
      </>
    );
  }
}

export default reduxWrapper.withRedux(FormSwift);
