import React from 'react';
import { ResetPasswordSuggestion } from './ResetPasswordSuggestion';
import { EmailExistsMessage } from './EmailExistsMessage';

export const ErrorMessageConstants = {
  CHECKOUT_LOGGED_OUT: 'checkout-logged-out',
  CHECKOUT_SIGN_IN_REDIRECT:
    'You must be signed in to complete your payment. Sign in below or create an account',
  DOCUMENT_NOT_FOUND: 'This document does not exist or has been deleted.',
  FREEWRITE_ERROR_REDIRECT:
    'Oops. You no longer have access to this document. The document owner has either revoked your access or this document no longer exists',
  INVALID_DETAILS: 'Something went wrong, there was an issue with the details',
  INVALID_PERMISSIONS:
    'You do not have permissions to view the document you tried to access because you are not the document owner. Your documents are available in your account.',
  INVALID_SESSION: 'Invalid User Session',
  IS_CURRENT_SUBSCRIBER: 'is-current-subscriber',
  SESSION_CREATION_FAILED:
    'There was an error creating your document. Please try again or contact us for help.',
  SESSION_DOES_NOT_EXIST: 'Session does not exist.',
  SIGN_IN_REQUIRED: 'Please sign in to view this document.',
};

// Maps error messages to a human readable format.
// This allows us to safely map error messgaes to JSX without
// having to use _dangerouslySetHTML.
export const statusCodeToMessage: {
  [key: string]: {
    isCritical?: boolean;
    getMessage: (
      email?: string,
      queryString?: string,
      onLinkClick?: () => void
    ) => string | JSX.Element;
  };
} = {
  'checkout-logged-out': {
    getMessage: () => 'Please create an account to complete your payment',
    isCritical: false,
  },
  'common-password': {
    getMessage: () =>
      'Passwords cannot be easily guessed phrases like "password", keyboard patterns such as "qwerty", or sequences such as "1234".',
    isCritical: false,
  },
  'confirm-payment-update': {
    getMessage: () =>
      'Your payment details have been submitted. Please confirm the update is correct on the Account Settings page.',
    isCritical: false,
  },
  'custom-template-not-public': {
    getMessage: () =>
      'This document does not exist. Check that you have the correct link or the document owner may not be collecting responses right now.',
    isCritical: false,
  },
  'email-does-not-match': {
    getMessage: () =>
      'Your email address does not match the invite code. Please use the email address that received the invite, or contact your admin for assistance.',
    isCritical: false,
  },
  'email-exists': {
    getMessage: EmailExistsMessage,
    isCritical: false,
  },
  'error-adding-fax-number': {
    getMessage: () =>
      'Sorry, we could not add the fax number to your account. Please contact customer service for assistance with this issue.',
    isCritical: false,
  },
  'fax-deletion-failed': {
    getMessage: () =>
      'There was an error deleting your fax. Please try again or contact us for help.',
    isCritical: false,
  },
  'fax-download-failed': {
    getMessage: () =>
      'There was an error with your download. Please refresh and try again or contact us for help.',
    isCritical: false,
  },
  'incorrect-password': {
    getMessage() {
      return <ResetPasswordSuggestion />;
    },
    isCritical: false,
  },
  'invalid-custom-template-share-key': {
    getMessage: () =>
      'Oops! You no longer have access to this document. The document owner has either revoked your access or this document no longer exists.',
    isCritical: false,
  },
  'invalid-deactivate-permissions': {
    getMessage: () =>
      'You must be the owner of the document to change its state.',
    isCritical: false,
  },
  'invalid-email': {
    getMessage: () => 'Please enter your email in the form you@email.com.',
    isCritical: false,
  },
  'invalid-name': {
    getMessage: () => 'Name is invalid',
    isCritical: false,
  },
  'invalid-password': {
    getMessage() {
      return (
        <>
          Your password is incorrect. Please try again or{' '}
          <a
            data-testid="reset-password-link"
            href="/forgot-password"
            style={{
              color: '#0070A9',
              textDecoration: 'underline',
            }}
          >
            reset your password.
          </a>
        </>
      );
    },
    isCritical: false,
  },
  'invalid-password-confirmation': {
    getMessage: () => 'Passwords do not match.',
    isCritical: false,
  },
  'invalid-permissions': {
    getMessage: () =>
      'You do not have permissions to view the document you tried to access because you are not the document owner. Your documents are available in your account.',
    isCritical: false,
  },
  'invalid-plan': {
    getMessage: () =>
      'There was an error with the plan you selected. Please contact us.',
    isCritical: true,
  },
  'invalid-site': {
    getMessage: () => 'There was an error with the site. Please contact us.',
    isCritical: true,
  },
  'invalid-tax-id': {
    getMessage: () => 'Invalid Tax ID',
    isCritical: false,
  },
  'invalid-user-session': {
    getMessage: () => 'Invalid user session',
    isCritical: false,
  },
  'invite-expired': {
    getMessage: () =>
      'The team invitation has expired. To join the team, please reach out to your admin to request a new invite.',
    isCritical: false,
  },
  'invite-invalid': {
    getMessage: () =>
      'The invitation code is invalid, please contact your admin to resend an invitation.',
    isCritical: false,
  },
  'invite-revoked': {
    getMessage: () =>
      'The invitation you received has been revoked. Please contact your admin to request a new invite or for more information.',
    isCritical: false,
  },
  'missing-info': {
    getMessage: () => 'Registration Failed. Missing email address or password.',
    isCritical: false,
  },
  'nelco-efile-failed': {
    getMessage() {
      return (
        <>
          <b>We had trouble e-filing your document.</b> This is unusual, so
          please try again.
        </>
      );
    },
    isCritical: false,
  },
  'no-seats-remaining': {
    getMessage: () =>
      'The team you’re trying to join is full. Please contact your admin to send you an invite',
    isCritical: false,
  },
  'registration-failed': {
    getMessage: EmailExistsMessage,
    isCritical: false,
  },
  'registration-failed-google': {
    getMessage: () =>
      'We were not able to create an account for you. This is unusual, so please try again.',
    isCritical: false,
  },
  'reset-password-suggestion-error': {
    // eslint-disable-next-line react/display-name
    getMessage: () => <ResetPasswordSuggestion />,
    isCritical: false,
  },
  'session-has-owner': {
    getMessage: () =>
      'The document specified is already owned by someone else.',
    isCritical: false,
  },
  'short-password': {
    getMessage: () => 'Please make your password 8 characters or longer.',
    isCritical: false,
  },
  'sign-in-failed': {
    getMessage(email?: string, queryString?: string) {
      const parsedQueryString = queryString?.replace(/&{0,1}warning[^&]*/, '');
      return (
        <>
          Sign in could not be completed. Please try again. You can also try a
          different Google email address or{' '}
          <a
            href={`/new-account?${parsedQueryString ?? ''}`}
            style={{
              color: '#0070A9',
              textDecoration: 'underline',
            }}
          >
            create a new account.
          </a>
        </>
      );
    },
    isCritical: false,
  },
  'sign-in-failed-generic': {
    getMessage: () =>
      'We had trouble signing you in. This is unusual, so please try again.',
    isCritical: false,
  },
  'sign-in-invalid': {
    getMessage: () =>
      'We were not able to get your account information from Google. This is unusual, so please try again.',
    isCritical: false,
  },
  'signin-required': {
    getMessage: () => 'You must be signed in to view this document.',
    isCritical: false,
  },
  'tax-export-failed': {
    getMessage() {
      return (
        <>
          <b>We had trouble exporting your document.</b> This is unusual, so
          please try again.
        </>
      );
    },
  },
  'tax-preview-failed': {
    getMessage() {
      return (
        <>
          <b>We were unable to load your preview.</b> This is unusual, so please
          try again.
        </>
      );
    },
  },
  'tax-validation-failed': {
    getMessage() {
      return (
        <>
          <b>Unresolved errors.</b> Fix marked fields to continue.
        </>
      );
    },
  },
  'template-duplication-failed': {
    getMessage: () => 'Your template was not duplicated.',
    isCritical: false,
  },
  'too-many-sign-in-attempts': {
    getMessage: () =>
      'There have been too many attempts to sign in. Please try again in a moment or reset your password.',
    isCritical: false,
  },
  'update-email-success': {
    getMessage: () => 'Your email address has been successfully updated.',
    isCritical: false,
  },
  'update-name-success': {
    getMessage: () => 'Your username has been successfully updated.',
    isCritical: false,
  },
  'update-password-success': {
    getMessage: () => 'Your password has been successfully updated.',
    isCritical: false,
  },
  'update-subscription-failed': {
    getMessage(email?: string, queryString?: string) {
      return (
        <>
          Could not migrate account to new plan. Please
          <a
            href={`/contactUs.php?${
              typeof queryString !== 'undefined' ? queryString : ''
            }`}
          >
            &nbsp;Contact Us&nbsp;
          </a>
          .
        </>
      );
    },
    isCritical: false,
  },
  'update-tax-id-success': {
    getMessage: () => 'Your tax ID has been successfully updated.',
    isCritical: false,
  },

  'wrong-email-or-password': {
    getMessage() {
      return (
        <>
          Please try again or{' '}
          <a
            data-testid="reset-password-link"
            href="/forgot-password"
            style={{
              color: '#0070A9',
              textDecoration: 'underline',
            }}
          >
            reset your password.
          </a>{' '}
          If you have signed in with Google before, try signing in with Google.
        </>
      );
    },
    isCritical: false,
  },
  'wrong-password': {
    getMessage() {
      return (
        <>
          Your password is incorrect. Please try again or{' '}
          <a
            data-testid="reset-password-link"
            href="/forgot-password"
            style={{
              color: '#0070A9',
              textDecoration: 'underline',
            }}
          >
            reset your password.
          </a>
        </>
      );
    },
    isCritical: false,
  },
};

const messageToNormalized: { [key: string]: string | undefined } = {
  '(221B): Unable to efile. Please try again or contact support if this persists.':
    'nelco-efile-failed',
  'custom-template-has-owner': 'session-has-owner',
  'document type not found': 'invalid-document-type',
  'Missing field: creator_user_id': 'signin-required',
  'Missing field: creator_user_token': 'signin-required',
  'Registration Failed. Missing email address or password.': 'missing-info',
  'This custom template is no longer active.':
    'invalid-custom-template-share-key',
  'This document is not publicly accessible.': 'custom-template-not-public',
  'This DocumentShare does not exist.': 'invalid-custom-template-share-key',
};

export const normalizeMessage = (message: string): string => {
  const normalized = messageToNormalized[message];
  return normalized || message;
};
