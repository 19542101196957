import * as Types from '../../schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserDetailsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserDetailsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email?: string | null;
    vatId?: string | null;
  } | null;
};

export type GetUserSubscriptionDetailsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserSubscriptionDetailsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    hasPaidAccess?: boolean | null;
    isCancelled?: boolean | null;
    isCurrentSubscriber?: boolean | null;
    planId?: string | null;
    organization?: {
      __typename?: 'Organization';
      id?: number | null;
      organizationAdminEmail?: string | null;
      isActive: boolean;
      isOrganizationAdmin: boolean;
    } | null;
    plan?: { __typename?: 'Plan'; planDescription?: string | null } | null;
    subscription?: {
      __typename?: 'Subscription';
      currency?: string | null;
      currentPeriodEndsAt?: string | null;
      country?: string | null;
      currentState?: string | null;
      interval?: number | null;
      intervalUnit?: string | null;
      subscriptionPrice?: number | null;
      taxIncluded?: boolean | null;
      product?: {
        __typename?: 'Product';
        pricePoint?: {
          __typename?: 'PricePoint';
          priceInCents?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
  userBilling?: {
    __typename?: 'UserBilling';
    lastFour?: string | null;
    nextBillingDate?: string | null;
  } | null;
};

export type GetUserSubscriptionPreviewQueryVariables = Types.Exact<{
  planHandle?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetUserSubscriptionPreviewQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    subscription?: {
      __typename?: 'Subscription';
      taxIncluded?: boolean | null;
    } | null;
    subscriptionPreview?: {
      __typename?: 'SubscriptionPreview';
      nextBillingManifest?: {
        __typename?: 'NextBillingManifest';
        totalTaxInCents?: number | null;
        subtotalInCents?: number | null;
        totalInCents?: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetEmailRemindersQueryVariables = Types.Exact<{
  senderUserId: Types.Scalars['Int'];
}>;

export type GetEmailRemindersQuery = {
  __typename?: 'Query';
  cancellationEmailReminders?: Array<{
    __typename?: 'EmailReminder';
    id: string;
    isSent: boolean;
    sendOn?: string | null;
    type: string;
  } | null> | null;
};

export type GetBillingDetailsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetBillingDetailsQuery = {
  __typename?: 'Query';
  userBilling?: {
    __typename?: 'UserBilling';
    lastFour?: string | null;
    nextBillingDate?: string | null;
  } | null;
};

export type GetChargifyFieldsQueryVariables = Types.Exact<{
  site: Types.Scalars['String'];
  isUpdatingPaymentInfo: Types.Scalars['Boolean'];
}>;

export type GetChargifyFieldsQuery = {
  __typename?: 'Query';
  chargifyFields?: {
    __typename?: 'ChargifyFields';
    isTestPurchase?: boolean | null;
    subscriptionId?: number | null;
    domain?: string | null;
    chargifyJsPublicKey?: string | null;
    gatewayHandle?: string | null;
  } | null;
};

export type GetUserOrganizationDetailsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserOrganizationDetailsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    organization?: {
      __typename?: 'Organization';
      id?: number | null;
      isOrganizationAdmin: boolean;
      organizationMemberDetails?: {
        __typename?: 'OrganizationMemberDetails';
        numberOfSeatsRemaining: number;
        numberOfSeatsTotal: number;
        members?: Array<{
          __typename?: 'OrganizationMembers';
          id: string;
          email: string;
          memberType: Types.MemberType;
          status: Types.MemberStatus;
        } | null> | null;
        invitations?: Array<{
          __typename?: 'OrganizationInvitations';
          email: string;
          expiresOn?: string | null;
          status: Types.InvitationStatus;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export const GetUserDetailsDocument = gql`
  query getUserDetails {
    user {
      id
      name
      email
      vatId
    }
  }
`;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserDetailsQuery,
    GetUserDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(
    GetUserDetailsDocument,
    options
  );
}
export function useGetUserDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDetailsQuery,
    GetUserDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(
    GetUserDetailsDocument,
    options
  );
}
export type GetUserDetailsQueryHookResult = ReturnType<
  typeof useGetUserDetailsQuery
>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<
  typeof useGetUserDetailsLazyQuery
>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<
  GetUserDetailsQuery,
  GetUserDetailsQueryVariables
>;
export const GetUserSubscriptionDetailsDocument = gql`
  query getUserSubscriptionDetails {
    user {
      id
      hasPaidAccess
      isCancelled
      isCurrentSubscriber
      organization {
        id
        organizationAdminEmail
        isActive
        isOrganizationAdmin
      }
      plan {
        planDescription
      }
      planId
      subscription {
        currency
        currentPeriodEndsAt
        country
        product {
          pricePoint {
            priceInCents
          }
        }
        currentState
        interval
        intervalUnit
        subscriptionPrice
        taxIncluded
      }
    }
    userBilling {
      lastFour
      nextBillingDate
    }
  }
`;

/**
 * __useGetUserSubscriptionDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSubscriptionDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserSubscriptionDetailsQuery,
    GetUserSubscriptionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserSubscriptionDetailsQuery,
    GetUserSubscriptionDetailsQueryVariables
  >(GetUserSubscriptionDetailsDocument, options);
}
export function useGetUserSubscriptionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSubscriptionDetailsQuery,
    GetUserSubscriptionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserSubscriptionDetailsQuery,
    GetUserSubscriptionDetailsQueryVariables
  >(GetUserSubscriptionDetailsDocument, options);
}
export type GetUserSubscriptionDetailsQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionDetailsQuery
>;
export type GetUserSubscriptionDetailsLazyQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionDetailsLazyQuery
>;
export type GetUserSubscriptionDetailsQueryResult = Apollo.QueryResult<
  GetUserSubscriptionDetailsQuery,
  GetUserSubscriptionDetailsQueryVariables
>;
export const GetUserSubscriptionPreviewDocument = gql`
  query getUserSubscriptionPreview($planHandle: String) {
    user {
      id
      subscription {
        taxIncluded
      }
      subscriptionPreview(planHandle: $planHandle) {
        nextBillingManifest {
          totalTaxInCents
          subtotalInCents
          totalInCents
        }
      }
    }
  }
`;

/**
 * __useGetUserSubscriptionPreviewQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionPreviewQuery({
 *   variables: {
 *      planHandle: // value for 'planHandle'
 *   },
 * });
 */
export function useGetUserSubscriptionPreviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserSubscriptionPreviewQuery,
    GetUserSubscriptionPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserSubscriptionPreviewQuery,
    GetUserSubscriptionPreviewQueryVariables
  >(GetUserSubscriptionPreviewDocument, options);
}
export function useGetUserSubscriptionPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserSubscriptionPreviewQuery,
    GetUserSubscriptionPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserSubscriptionPreviewQuery,
    GetUserSubscriptionPreviewQueryVariables
  >(GetUserSubscriptionPreviewDocument, options);
}
export type GetUserSubscriptionPreviewQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionPreviewQuery
>;
export type GetUserSubscriptionPreviewLazyQueryHookResult = ReturnType<
  typeof useGetUserSubscriptionPreviewLazyQuery
>;
export type GetUserSubscriptionPreviewQueryResult = Apollo.QueryResult<
  GetUserSubscriptionPreviewQuery,
  GetUserSubscriptionPreviewQueryVariables
>;
export const GetEmailRemindersDocument = gql`
  query getEmailReminders($senderUserId: Int!) {
    cancellationEmailReminders(senderUserId: $senderUserId) {
      id
      isSent
      sendOn
      type
    }
  }
`;

/**
 * __useGetEmailRemindersQuery__
 *
 * To run a query within a React component, call `useGetEmailRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailRemindersQuery({
 *   variables: {
 *      senderUserId: // value for 'senderUserId'
 *   },
 * });
 */
export function useGetEmailRemindersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmailRemindersQuery,
    GetEmailRemindersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmailRemindersQuery,
    GetEmailRemindersQueryVariables
  >(GetEmailRemindersDocument, options);
}
export function useGetEmailRemindersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmailRemindersQuery,
    GetEmailRemindersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmailRemindersQuery,
    GetEmailRemindersQueryVariables
  >(GetEmailRemindersDocument, options);
}
export type GetEmailRemindersQueryHookResult = ReturnType<
  typeof useGetEmailRemindersQuery
>;
export type GetEmailRemindersLazyQueryHookResult = ReturnType<
  typeof useGetEmailRemindersLazyQuery
>;
export type GetEmailRemindersQueryResult = Apollo.QueryResult<
  GetEmailRemindersQuery,
  GetEmailRemindersQueryVariables
>;
export const GetBillingDetailsDocument = gql`
  query getBillingDetails {
    userBilling {
      lastFour
      nextBillingDate
    }
  }
`;

/**
 * __useGetBillingDetailsQuery__
 *
 * To run a query within a React component, call `useGetBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >(GetBillingDetailsDocument, options);
}
export function useGetBillingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBillingDetailsQuery,
    GetBillingDetailsQueryVariables
  >(GetBillingDetailsDocument, options);
}
export type GetBillingDetailsQueryHookResult = ReturnType<
  typeof useGetBillingDetailsQuery
>;
export type GetBillingDetailsLazyQueryHookResult = ReturnType<
  typeof useGetBillingDetailsLazyQuery
>;
export type GetBillingDetailsQueryResult = Apollo.QueryResult<
  GetBillingDetailsQuery,
  GetBillingDetailsQueryVariables
>;
export const GetChargifyFieldsDocument = gql`
  query getChargifyFields($site: String!, $isUpdatingPaymentInfo: Boolean!) {
    chargifyFields(site: $site, isUpdatingPaymentInfo: $isUpdatingPaymentInfo) {
      isTestPurchase
      subscriptionId
      domain
      chargifyJsPublicKey
      gatewayHandle
    }
  }
`;

/**
 * __useGetChargifyFieldsQuery__
 *
 * To run a query within a React component, call `useGetChargifyFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargifyFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargifyFieldsQuery({
 *   variables: {
 *      site: // value for 'site'
 *      isUpdatingPaymentInfo: // value for 'isUpdatingPaymentInfo'
 *   },
 * });
 */
export function useGetChargifyFieldsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChargifyFieldsQuery,
    GetChargifyFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChargifyFieldsQuery,
    GetChargifyFieldsQueryVariables
  >(GetChargifyFieldsDocument, options);
}
export function useGetChargifyFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChargifyFieldsQuery,
    GetChargifyFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChargifyFieldsQuery,
    GetChargifyFieldsQueryVariables
  >(GetChargifyFieldsDocument, options);
}
export type GetChargifyFieldsQueryHookResult = ReturnType<
  typeof useGetChargifyFieldsQuery
>;
export type GetChargifyFieldsLazyQueryHookResult = ReturnType<
  typeof useGetChargifyFieldsLazyQuery
>;
export type GetChargifyFieldsQueryResult = Apollo.QueryResult<
  GetChargifyFieldsQuery,
  GetChargifyFieldsQueryVariables
>;
export const GetUserOrganizationDetailsDocument = gql`
  query getUserOrganizationDetails {
    user {
      id
      organization {
        id
        isOrganizationAdmin
        organizationMemberDetails {
          numberOfSeatsRemaining
          numberOfSeatsTotal
          members {
            id
            email
            memberType
            status
          }
          invitations {
            email
            expiresOn
            status
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganizationDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOrganizationDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserOrganizationDetailsQuery,
    GetUserOrganizationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserOrganizationDetailsQuery,
    GetUserOrganizationDetailsQueryVariables
  >(GetUserOrganizationDetailsDocument, options);
}
export function useGetUserOrganizationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOrganizationDetailsQuery,
    GetUserOrganizationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserOrganizationDetailsQuery,
    GetUserOrganizationDetailsQueryVariables
  >(GetUserOrganizationDetailsDocument, options);
}
export type GetUserOrganizationDetailsQueryHookResult = ReturnType<
  typeof useGetUserOrganizationDetailsQuery
>;
export type GetUserOrganizationDetailsLazyQueryHookResult = ReturnType<
  typeof useGetUserOrganizationDetailsLazyQuery
>;
export type GetUserOrganizationDetailsQueryResult = Apollo.QueryResult<
  GetUserOrganizationDetailsQuery,
  GetUserOrganizationDetailsQueryVariables
>;
